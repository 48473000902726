<template>
  <div class="userList">
    <searchBar :formItems="formItems" @search="search" />
    <div style="margin-bottom: 10px">
      <el-button type="primary" @click="add()">
        <i class="el-icon-plus" />新增
      </el-button>
    </div>
    <div class="tableBox">
      <el-table
        :data="tableData"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center' }"
        border
        style="width: 100%"
      >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="fullName"
          label="收货人姓名"
          textAlign="left"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="nickName"
          label="归属人姓名"
          textAlign="left"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="phoneNumber"
          label="手机号码"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="province"
          label="省份"
        ></el-table-column>
        <el-table-column
          prop="city"
          label="城市"
        ></el-table-column>
        <el-table-column
          prop="county"
          label="区县"
        ></el-table-column>
        <el-table-column
          prop="detail"
          label="详细收货地址信息"
        ></el-table-column>
        <el-table-column prop label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editData(scope.row)"
              >编辑</el-button
            >
            <el-button @click="deleteData(scope.row)" type="text" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fenye">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <ActivityDialog ref="dialog" @search="initPage" />
  </div>
</template>

<script>
import searchBar from "@/components/SearchBar/index";
import ActivityDialog from "./components/dialog.vue";
export default {
  components: { searchBar, ActivityDialog },
  data() {
    return {
      page: {
        page: 1,
        pageSize: 10,
      },
      params: {
      },
      total: 0,
      tableData: [],
      formItems: [
        {
          label: "",
          type: "el-input",
          fieldName: "fullName",
          attrs: {
            placeholder: "收货人姓名",
          },
        },
      ],
    };
  },
  methods: {
    changeEnable(enable, id) {
      const data = enable === "0" ? "1" : "0";
      this.getAjax("api/activity/enable", { enable: data, id }, "post").then(
        (res) => {
          this.initPage();
          this.message("操作成功");
        }
      );
    },
    indexMethod(index) {
      return index + 1 + (this.page.page - 1) * this.page.pageSize;
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.initPage();
    },
    search(opt) {
      //搜索
      this.page.page = 1;
      this.params = opt;
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/address/list",
        { ...this.params, ...this.page },
        "get"
      ).then((res) => {
        this.tableData = res.data.list.records;
        this.total = res.data.list.total;
      });
    },
    add() {
      this.$refs.dialog.visible = true;
    },
    editData(item) {
      this.$refs.dialog.getInfo(item.id);
    },
    deleteData(item) {
      this.$confirm("确定删除吗？").then(() => {
        this.getAjax("api/address/del", { id: item.id }, "post").then(
          (res) => {
            this.$message.success("删除成功");
            this.initPage();
          }
        );
      });
    },
  },
  mounted() {
    this.initPage();
  },
};
</script>

<style lang="less">
.tianfulove {
  width: 100%;
  height: 100%;
  display: flex;
  .leftBox {
    width: 10%;
min-width:130px;
    height: 100%;
    background: #545c64;
  }
  .rightBox {
    flex: 1;
    padding: 20px;
    .fenye {
      text-align: right;
      padding: 15px 10px;
    }
  }
}
</style>
